import { Observable, of } from 'rxjs';
import { AppConstants } from '../app-constants';
import { MessageProviderService } from './message-provider/message-provider.service';

export abstract class BaseServiceProvider {

  constructor(public messageProvider: MessageProviderService) {}

  protected getMessageProvider(): any {
    return this.messageProvider;
  }

  protected handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      this.messageProvider.add(error.message, AppConstants.ERROR);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  protected log(message: string): void {
    console.log(message);
  }
}
