import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScheduleCallbackResponse } from '../schedule-callback-provider/schedule-callback-response';

@Injectable({
  providedIn: 'root'
})
export class StateDataProviderService {

  private stateData = new BehaviorSubject<ScheduleCallbackResponse>({} as ScheduleCallbackResponse);

  addData(data: ScheduleCallbackResponse): void {
    this.stateData.next(data);
  }

  getData(): Observable<any> {
    return this.stateData.asObservable()
  }
}
