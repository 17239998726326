export class SchedulingWindow {

  public static SLOTS = [
    {key: 800, interval: '08:00 a.m. - 08:15 a.m. ET', time: '08:00:00', label: 'between 08:00 a m and 08:15 a m'},
    {key: 815, interval: '08:15 a.m. - 08:30 a.m. ET', time: '08:15:00', label: 'between 08:15 a m and 08:30 a m'},
    {key: 830, interval: '08:30 a.m. - 08:45 a.m. ET', time: '08:30:00', label: 'between 08:30 a m and 08:45 a m'},
    {key: 845, interval: '08:45 a.m. - 09:00 a.m. ET', time: '08:45:00', label: 'between 08:45 a m and 09:00 a m'},
    {key: 900, interval: '09:00 a.m. - 09:15 a.m. ET', time: '09:00:00', label: 'between 09:00 a m and 09:15 a m'},
    {key: 915, interval: '09:15 a.m. - 09:30 a.m. ET', time: '09:15:00', label: 'between 09:15 a m and 09:30 a m'},
    {key: 930, interval: '09:30 a.m. - 09:45 a.m. ET', time: '09:30:00', label: 'between 09:30 a m and 09:45 a m'},
    {key: 945, interval: '09:45 a.m. - 10:00 a.m. ET', time: '09:45:00', label: 'between 09:45 a m and 10:00 a m'},
    {key: 1000, interval: '10:00 a.m. - 10:15 a.m. ET', time: '10:00:00', label: 'between 10:00 a m and 10:15 a m'},
    {key: 1015, interval: '10:15 a.m. - 10:30 a.m. ET', time: '10:15:00', label: 'between 10:15 a m and 10:30 a m'},
    {key: 1030, interval: '10:30 a.m. - 10:45 a.m. ET', time: '10:30:00', label: 'between 10:30 a m and 10:45 a m'},
    {key: 1045, interval: '10:45 a.m. - 11:00 a.m. ET', time: '10:45:00', label: 'between 10:45 a m and 11:00 a m'},
    {key: 1100, interval: '11:00 a.m. - 11:15 a.m. ET', time: '11:00:00', label: 'between 11:00 a m and 11:15 a m'},
    {key: 1115, interval: '11:15 a.m. - 11:30 a.m. ET', time: '11:15:00', label: 'between 11:15 a m and 11:30 a m'},
    {key: 1130, interval: '11:30 a.m. - 11:45 a.m. ET', time: '11:30:00', label: 'between 11:30 a m and 11:45 a m'},
    {key: 1145, interval: '11:45 a.m. - 12:00 p.m. ET', time: '11:45:00', label: 'between 11:45 a m and 12:00 p m'},
    {key: 1200, interval: '12:00 p.m. - 12:15 p.m. ET', time: '12:00:00', label: 'between 12:00 p m and 12:15 p m'},
    {key: 1215, interval: '12:15 p.m. - 12:30 p.m. ET', time: '12:15:00', label: 'between 12:15 p m and 12:30 p m'},
    {key: 1230, interval: '12:30 p.m. - 12:45 p.m. ET', time: '12:30:00', label: 'between 12:30 p m and 12:45 p m'},
    {key: 1245, interval: '12:45 p.m. - 01:00 p.m. ET', time: '12:45:00', label: 'between 12:45 p m and 01:00 p m'},
    {key: 1300, interval: '01:00 p.m. - 01:15 p.m. ET', time: '13:00:00', label: 'between 01:00 p m and 01:15 p m'},
    {key: 1315, interval: '01:15 p.m. - 01:30 p.m. ET', time: '13:15:00', label: 'between 01:15 p m and 01:30 p m'},
    {key: 1330, interval: '01:30 p.m. - 01:45 p.m. ET', time: '13:30:00', label: 'between 01:30 p m and 01:45 p m'},
    {key: 1345, interval: '01:45 p.m. - 02:00 p.m. ET', time: '13:45:00', label: 'between 01:45 p m and 02:00 p m'},
    {key: 1400, interval: '02:00 p.m. - 02:15 p.m. ET', time: '14:00:00', label: 'between 02:00 p m and 02:15 p m'},
    {key: 1415, interval: '02:15 p.m. - 02:30 p.m. ET', time: '14:15:00', label: 'between 02:15 p m and 02:30 p m'},
    {key: 1430, interval: '02:30 p.m. - 02:45 p.m. ET', time: '14:30:00', label: 'between 02:30 p m and 02:45 p m'},
    {key: 1445, interval: '02:45 p.m. - 03:00 p.m. ET', time: '14:45:00', label: 'between 02:45 p m and 03:00 p m'},
    {key: 1500, interval: '03:00 p.m. - 03:15 p.m. ET', time: '15:00:00', label: 'between 03:00 p m and 03:15 p m'},
    {key: 1515, interval: '03:15 p.m. - 03:30 p.m. ET', time: '15:15:00', label: 'between 03:15 p m and 03:30 p m'},
    {key: 1530, interval: '03:30 p.m. - 03:45 p.m. ET', time: '15:30:00', label: 'between 03:30 p m and 03:45 p m'},
    {key: 1545, interval: '03:45 p.m. - 04:00 p.m. ET', time: '15:45:00', label: 'between 03:45 p m and 04:00 p m'},
    {key: 1600, interval: '04:00 p.m. - 04:15 p.m. ET', time: '16:00:00', label: 'between 04:00 p m and 04:15 p m'},
    {key: 1615, interval: '04:15 p.m. - 04:30 p.m. ET', time: '16:15:00', label: 'between 04:15 p m and 04:30 p m'},
    {key: 1630, interval: '04:30 p.m. - 04:45 p.m. ET', time: '16:30:00', label: 'between 04:30 p m and 04:45 p m'},
    {key: 1645, interval: '04:45 p.m. - 05:00 p.m. ET', time: '16:45:00', label: 'between 04:45 p m and 05:00 p m'},
    {key: 1700, interval: '05:00 p.m. - 05:15 p.m. ET', time: '17:00:00', label: 'between 05:00 p m and 05:15 p m'},
    {key: 1715, interval: '05:15 p.m. - 05:30 p.m. ET', time: '17:15:00', label: 'between 05:15 p m and 05:30 p m'},
    {key: 1730, interval: '05:30 p.m. - 05:45 p.m. ET', time: '17:30:00', label: 'between 05:30 p m and 05:45 p m'},
    {key: 1745, interval: '05:45 p.m. - 06:00 p.m. ET', time: '17:45:00', label: 'between 05:45 p m and 06:00 p m'},
    {key: 1800, interval: '06:00 p.m. - 06:15 p.m. ET', time: '18:00:00', label: 'between 06:00 p m and 06:15 p m'},
    {key: 1815, interval: '06:15 p.m. - 06:30 p.m. ET', time: '18:15:00', label: 'between 06:15 p m and 06:30 p m'},
    {key: 1830, interval: '06:30 p.m. - 06:45 p.m. ET', time: '18:30:00', label: 'between 06:30 p m and 06:45 p m'},
    {key: 1845, interval: '06:45 p.m. - 07:00 p.m. ET', time: '18:45:00', label: 'between 06:45 p m and 07:00 p m'},
    {key: 1900, interval: '07:00 p.m. - 07:15 p.m. ET', time: '19:00:00', label: 'between 07:00 p m and 07:15 p m'},
    {key: 1915, interval: '07:15 p.m. - 07:30 p.m. ET', time: '19:15:00', label: 'between 07:15 p m and 07:30 p m'},
    {key: 1930, interval: '07:30 p.m. - 07:45 p.m. ET', time: '19:30:00', label: 'between 07:30 p m and 07:45 p m'},
    {key: 1945, interval: '07:45 p.m. - 08:00 p.m. ET', time: '19:45:00', label: 'between 07:45 p m and 08:00 p m'},
    {key: 2000, interval: '08:00 p.m. - 08:15 p.m. ET', time: '20:00:00', label: 'between 08:00 p m and 08:15 p m'},
    {key: 2015, interval: '08:15 p.m. - 08:30 p.m. ET', time: '20:15:00', label: 'between 08:15 p m and 08:30 p m'},
    {key: 2030, interval: '08:30 p.m. - 08:45 p.m. ET', time: '20:30:00', label: 'between 08:30 p m and 08:45 p m'},
    {key: 2045, interval: '08:45 p.m. - 09:00 p.m. ET', time: '20:45:00', label: 'between 08:45 p m and 09:00 p m'},
    {key: 2100, interval: '09:00 p.m. - 09:15 p.m. ET', time: '21:00:00', label: 'between 09:00 p m and 09:15 p m'},
    {key: 2115, interval: '09:15 p.m. - 09:30 p.m. ET', time: '21:15:00', label: 'between 09:15 p m and 09:30 p m'},
    {key: 2130, interval: '09:30 p.m. - 09:45 p.m. ET', time: '21:30:00', label: 'between 09:30 p m and 09:45 p m'},
    {key: 2145, interval: '09:45 p.m. - 10:00 p.m. ET', time: '21:45:00', label: 'between 09:45 p m and 10:00 p m'}
  ];

  public static PERIOD = 60;

  public static CUTOFF_THRESHOLD = 15;
}
