import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../app-constants';
import { BaseServiceProvider } from '../base-service-provider';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { CancelResponse } from './cancel-response';
import { v4 as uuidv4} from 'uuid';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CancelCallbackProviderService extends BaseServiceProvider {

  private cancelCallProviderServiceUrl: string;
  private cancelCallProviderApiClientId: string;
  private cancelResponse = {
    result: '',
    comments: ''
  }

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
    super(messageProvider);
    const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
    const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
    this.cancelCallProviderServiceUrl = envConfig.cancelCallbackServiceUrl;
    this.cancelCallProviderApiClientId = secureKeys.apiClientId;
  }

  cancelCallback(callId: string): Observable<CancelResponse> {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('client_id', this.cancelCallProviderApiClientId)
    .set('X-NW-Message-ID', 'CAN-CALL-AC-' + uuidv4());

    return this.http.delete<any>(this.cancelCallProviderServiceUrl.replace(/(\$\w+?\$)/g, callId), {headers}).pipe(
      map(payload => {
        return payload.response ? payload.response : this.cancelResponse;
      }),
      catchError(this.handleError<any>('cancelCallback', [this.cancelResponse]))
    );
  }
}
