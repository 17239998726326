import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthContext } from './auth-context';
import { v4 as uuidv4} from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { AppConstants } from '../../app-constants';
import { catchError, map } from 'rxjs/operators';
import { BaseServiceProvider } from '../base-service-provider';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseAuthProviderService extends BaseServiceProvider {

  private enterpriseAuthServiceUrl: string;
  private enterpriseAuthServiceClientId: string;

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
    super(messageProvider);
    const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
    const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
    this.enterpriseAuthServiceUrl = envConfig.enterpriseUserAuthServiceUrl;
    this.enterpriseAuthServiceClientId = secureKeys.apiClientId;
  }

  getAuth4SystemRealm(): Observable<AuthContext> {
    const headers = new HttpHeaders()
                  .set('Content-Type', 'application/json')
                  .set('client_id', this.enterpriseAuthServiceClientId)
                  .set('X-NW-Message-ID', 'AUTH-SYS-' + uuidv4());

    const options = { headers };

    return this.http.post<any>(this.enterpriseAuthServiceUrl, {}, options).pipe(
      map(payload => {
        return {
          accessToken: payload.access_token,
          tokenType: payload.token_type,
          expiresIn: payload.expires_in
        }
      }),
      catchError(this.handleError<AuthContext>('getAuth4SystemRealm',
        {
          accessToken: '',
          tokenType: '',
          expiresIn: ''
        })
      )
    );
  }

}
