import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SchedulingWindow } from '../constants/SchedulingWindow';

export class InputDateValidator {
  static content(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const inputDate = control.value;
      if (!control || !inputDate) {
        return {inputDateInvalid: true};
      }
      if (InputDateValidator.withinDaysFromNow(inputDate, SchedulingWindow.PERIOD)) {
        return null;
      } else {
        return {inputDateInvalid: true};
      }
    };
  }

  private static withinDaysFromNow(inputDate: any, days: number): boolean {
    const now = new Date();
    const nowTimeInMillis = now.getTime();

    const daysFromNow = new Date(nowTimeInMillis);
    daysFromNow.setDate(daysFromNow.getDate() + days);

    const usrInput = new Date(nowTimeInMillis);
    usrInput.setDate(inputDate.day);
    usrInput.setMonth(inputDate.month - 1);
    usrInput.setFullYear(inputDate.year);

    return (nowTimeInMillis <= usrInput.getTime() && usrInput.getTime() <= daysFromNow.getTime());
  }
}
