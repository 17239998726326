import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppConstants } from '../../app-constants';


@Injectable({
  providedIn: 'root'
})
export class AccessGuardProviderService implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const rerouteUrl: string = route.data.rerouteUrl;
    if (AppConstants.REGEX.FROM_URL.test(this.router.url)) {
      return true;
    } else {
      this.router.navigate([rerouteUrl], {queryParams: {reason: AppConstants.DISALLOWED_ROUTE}});
      return false;
    }
  }
}
