<div class="container">
  <!-- header -->
  <header role="banner">
    <div class="page-header">
      <bolt-logo></bolt-logo>
    </div>
  </header>

  <main role="main">
    <br/>
    <h1 class="bolt-heading-lg bolt-heading-teal-medium title">Sorry, something is amiss</h1>
    <bolt-notification *ngFor="let m of messages" type="{{m.type}}" id="notifications">{{m.text}}</bolt-notification>
    <br/>
  </main>

  <!-- footer -->
  <div class="bolt-footer bolt-footer-container">
    <div class="bolt-row">
        <div class="bolt-col bolt-footer-logo-basic">
            <bolt-logo color="white" type="nw-vertical" size="md" type="nw-mark"></bolt-logo>
        </div>
    </div>
    <p class="bolt-text-sm bolt-footer-disclaimer">
         &copy;{{currentYear}}. Nationwide Mutual Insurance Company.
    </p>
    <span id="teconsent"></span>
  <div>
</div>
