import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'click-to-callback';
  loading = false;
  constructor(private router: Router) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.loading = true;
      } else if (e instanceof NavigationEnd ||
                 e instanceof NavigationCancel ||
                 e instanceof NavigationError) {
        this.loading = false;
      }
    });
  }
}
