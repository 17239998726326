import { Inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { WINDOW } from '../../shared/utils/window-provider';
import { AppConstants } from '../../app-constants';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';

@Injectable({
  providedIn: 'root'
})
export class EnvConfigProviderService {

  private configUrl = '/assets/config/config.json';

  constructor(private cacheProvider: CacheProviderService,
              @Inject(WINDOW) private window: Window) { }

  public init(): any {
    return from(
      fetch(this.configUrl).then((response) => {
        return response.json();
      })
    ).pipe(
      map((config: any) => {
        let envConfig = config.internal;
        const hostName = this.window.location.hostname;
        if (hostName.indexOf('nationwide.com') > 0) {
          envConfig = config.external;
        }
        this.cacheProvider.add(AppConstants.DYNAMIC_ENV_CONFIG, envConfig);
        return envConfig;
      })).toPromise();
  }
}
