import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExecutionPhaseProviderService } from '../../services/execution-phase-provider/execution-phase-provider.service';
import { AppConstants } from '../../app-constants';
import { CancelCallbackProviderService } from '../../services/cancel-callback-provider/cancel-callback-provider.service';
import { StateDataProviderService } from '../../services/state-data-provider/state-data-provider.service';
import { SchedulingWindow } from '../../shared/constants/SchedulingWindow';
import { ScheduleBaseComponent } from '../schedule-base/schedule-base.component';

@Component({
  selector: 'app-cancel-reschedule-call',
  templateUrl: './cancel-reschedule-call.component.html',
  styleUrls: ['./cancel-reschedule-call.component.scss']
})
export class CancelRescheduleCallComponent extends ScheduleBaseComponent implements OnInit {

  callScheduledDate: Date = new Date();
  callScheduledTime: string = '';
  callbackNumber: string = '';

  callCancelRequested: boolean = false;
  callCancelConfirmed: boolean = false;
  callCancelSuccessful: boolean = false;
  callRescheduleRequested: boolean = false;
  callCancelAbandoned: boolean = false;

  messages: any = [];

  constructor(public router: Router,
              public injector: Injector) {
    super(AppConstants.EXECUTION_PHASES.CANCEL);
  }

  ngOnInit(): void {
    this.injector.get<StateDataProviderService>(StateDataProviderService).getData().subscribe(data => {
      if (data !== undefined && data !== '{}') {
        window.sessionStorage.setItem(AppConstants.DUPLICATE_CALL_DATA, JSON.stringify(data));
      }
      let contextData = JSON.parse(window.sessionStorage.getItem(AppConstants.DUPLICATE_CALL_DATA) || '');
      this.callScheduledDate =  this.getScheduledDate(contextData);
      this.callScheduledTime = this.getScheduledTimeSlot(this.callScheduledDate);
      this.callbackNumber = contextData['duplicatePhoneNumber'];
    });
  }

  cancelCall(): void {
    this.callCancelRequested = true;
  }

  cancelYes(): void {
    this.callCancelConfirmed = true;
    // call service method to cancel the existing call
    this.cancelAlreadyScheduledCallback();
  }

  cancelNo(): void {
    this.callCancelAbandoned = true;
    this.messages.push({text: 'You chose to accept the existing call', type: AppConstants.INFO});
    window.sessionStorage.removeItem(AppConstants.DUPLICATE_CALL_DATA);
  }

  reschedule(): void {
    this.callRescheduleRequested = true;
    this.cancelAlreadyScheduledCallback();
    const appId = window.sessionStorage.getItem(AppConstants.APPLICATION_ID);
    this.injector.get<ExecutionPhaseProviderService>(ExecutionPhaseProviderService).addPhase(AppConstants.EXECUTION_PHASES.RESCHEDULE);
    this.router.navigate(['/c2cb/schedule'], {fragment: 'appId='+ appId});
  }

  private getScheduledDate(contextData: any): Date {
    let scheduledDate = new Date(contextData['duplicateScheduledTime']);
    // add 1 min as the scheduled time sent is 1 min short
    scheduledDate.setMinutes(scheduledDate.getMinutes() + 1);
    return scheduledDate;
  }

  private getScheduledTimeSlot(scheduledDate: Date): string {
    let minute = scheduledDate.getMinutes();
    if (minute >= 45) {
      minute = 45;
    } else if (minute >= 30) {
      minute = 30;
    } else if (minute >= 15) {
      minute = 15;
    } else {
      minute = 0;
    }
    let scheduledMilTime: number = scheduledDate.getHours() * 100 + minute;
    return SchedulingWindow.SLOTS.filter(i => i.key === scheduledMilTime)[0].interval;
  }

  private cancelAlreadyScheduledCallback() {
    let contextData = JSON.parse(window.sessionStorage.getItem(AppConstants.DUPLICATE_CALL_DATA) || '');
    this.injector.get<CancelCallbackProviderService>(CancelCallbackProviderService).cancelCallback(contextData['duplicateRecordId']).subscribe(data => {
      this.messages = [];
      this.callCancelSuccessful = false;
      if (data.result === 'SUCCESS') {
        this.callCancelSuccessful = true;
        this.setPageHeaderAndTitle(AppConstants.EXECUTION_PHASES.CANCEL.complete);
        this.messages.push({ text: AppConstants.MESSAGES.CANCEL_SUCCESS, type: AppConstants.SUCCESS });
      } else {
        this.messages.push({ text: data.comments, type: AppConstants.ERROR });
      }
    });
  }

}
