import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SchedulingWindow } from '../../shared/constants/SchedulingWindow';
import { AppConstants } from '../../app-constants';
import { CalendarHelper } from '../../shared/utils/calendar-helper';
import { DateVals } from '../../shared/utils/date-vals';
import { BusinessHours } from '../business-hours-provider/business-hours';
import { BusinessCalendar } from './business-calendar';

@Injectable({
  providedIn: 'root'
})
export class BusinessCalendarProviderService {

  businessClosedDates: NgbDateStruct[] = [];

  constructor(public calendar: NgbCalendar,
              public calendarHelper: CalendarHelper) {
  }

  public getBusinessCalendar(businessHours: BusinessHours[], closedDates: []): BusinessCalendar {
    const minDate = this.calculateMinDate(businessHours);
    const maxDate =  this.calculateMaxDate(minDate.year, minDate.month - 1, minDate.day);
    this.businessClosedDates = closedDates;
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.addOtherNonworkingDays(businessHours);
    return {
      minDate: minDate,
      maxDate: maxDate,
      closedDate: this.disableClosedDates()
    };
  }

  private calculateMinDate(businessHours: BusinessHours[]): DateVals {
    const now = new Date();
    const minDate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
    // Date evaluate day as Sunday = 0, Monday = 1, ..., Saturday = 6
    // BusinessHoursProviderService evaluates day as Sunday = 1, Monday = 2, ..., Saturday = 7
    // hence adding 1 for comparison
    const myDayOfWeek = now.getDay() + 1;
    if (myDayOfWeek > 1) {
      const businessHours4Day = businessHours.filter(k => parseInt(k.dayOfWeek, 10) === myDayOfWeek)[0];
      if (businessHours4Day != null) {
        const closeTimeMil = this.calendarHelper.convertToMilTime(businessHours4Day.closeTime);
        const cutoffTime = closeTimeMil - SchedulingWindow.PERIOD;
        if (this.calendarHelper.getMilTime(this.calendarHelper.toUSEasternTime(now)) > cutoffTime) {
          minDate.day += 1;
        }
      }
    }
    return minDate;
  }

  private calculateMaxDate(year: number, month: number, day: number): DateVals {
    const  maxDate = new Date(year, month, day);
    maxDate.setDate(maxDate.getDate() + SchedulingWindow.PERIOD);
    return {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate()
    };
  }

  private addOtherNonworkingDays(businessHours: BusinessHours[]): void {
    const nonWorkingDays = this.getNonworkingDays(businessHours);
    const currentDate = this.getDateByAddingDays(0);
    const endDate = this.getDateByAddingDays(SchedulingWindow.PERIOD);
    while (currentDate <= endDate) {
      if (nonWorkingDays.includes(currentDate.getDay())) {
        // add one to month as the month is 1-based
        this.businessClosedDates.push({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});
      }
      // get the next date
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  private getDateByAddingDays(noOfDays: number): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    if (noOfDays > 0) {
      date.setDate(date.getDate() + noOfDays);
    }
    return date;
  }

  private getNonworkingDays(businessHours: BusinessHours[]): number[] {
    const nonWorkingDays: number[] = [0];
    const dayOfWeek = 'dayOfWeek';
    const workingDays = businessHours.map(dow => parseInt(dow[dayOfWeek], 10));
    if (!workingDays.includes(AppConstants.SATURDAY)) {
      nonWorkingDays.push(AppConstants.SATURDAY - 1);
    }
    return nonWorkingDays;
  }

  private disableClosedDates(): any {
    return (date: NgbDateStruct, current: {month: number, year: number}) => {
      return this.businessClosedDates.find(x => NgbDate.from(x)?.equals(date)) ? true : false;
    };
  }
}
