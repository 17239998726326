import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExecutionPhase } from './execution-phase';

@Injectable({
  providedIn: 'root'
})
export class ExecutionPhaseProviderService {

  private excutionPhase = new BehaviorSubject<ExecutionPhase>({} as ExecutionPhase);

  addPhase(phase: ExecutionPhase): void {
    this.excutionPhase.next(phase);
  }

  getPhase(): Observable<ExecutionPhase> {
    return this.excutionPhase.asObservable();
  }
}
