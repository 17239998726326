import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhoneNumberFormat } from '../formatters/phone-number-format';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {

  constructor( public ngControl: NgControl, private phoneNumberFormat: PhoneNumberFormat) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any): void {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any): void {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event: any, backspace: any): void {
    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    newVal = this.phoneNumberFormat.getStandardFormat(newVal);
    this.ngControl.valueAccessor?.writeValue(newVal);
  }
}
