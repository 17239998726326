import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BusinessHours } from './business-hours';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { v4 as uuidv4} from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { BusinessHoursResponse } from './business-hours-response';
import { BaseServiceProvider } from '../base-service-provider';
import { AppConstants } from '../../app-constants';


@Injectable({
  providedIn: 'root'
})
export class BusinessHoursProviderService extends BaseServiceProvider {

  private businessHoursServiceUrl: string;
  private businessHoursApiClientId: string;

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
    super( messageProvider);
    const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
    const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
    this.businessHoursServiceUrl = envConfig.businessHoursServiceUrl;
    this.businessHoursApiClientId = secureKeys.apiClientId;
  }

  getBusinessHours4Application(calltype: string): Observable<BusinessHours[]> {
    const headers = new HttpHeaders()
                  .set('Content-Type', 'application/json')
                  .set('client_id', this.businessHoursApiClientId)
                  .set('X-NW-Message-ID', 'SCH-CALL-BH-' + uuidv4());

    return this.http.get<BusinessHoursResponse>(this.businessHoursServiceUrl.replace(/(\$\w+?\$)/g, calltype) , {headers}).pipe(
      map(payload => {
        return payload.response;
      }),
      catchError(this.handleError<BusinessHours[]>('getBusinessHours4Application', [
        {
          calltype: '',
          dayOfWeek: '',
          timeZone:  '',
          openTime:  '',
          closeTime:  ''
        }
      ]))
    );
  }

}
