import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import '@nationwide-bolt/bundle-core';
import { BoltDirectivesModule } from '@nationwide-bolt/ng-directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ContextCacheProviderService } from './services/context-cache-provider/context-cache-provider.service';
import { SchduleACallResolver } from './schedule-a-call/schedule-a-call.resolve';
import { EnvConfigProviderService } from './services/env-config-provider/env-config-provider.service';
import { CalendarHelper } from './shared/utils/calendar-helper';
import { HttpSecurityInterceptor } from './interceptors/http-security-interceptor';
import { WINDOW_PROVIDERS } from './shared/utils/window-provider';
import { CustomNgbDateFormatter } from './shared/formatters/custom-ngb-date-format';
import { SetFocusDirective } from './shared/directives/set-focus.directive';
import { ScheduleErrorComponent } from './schedule-a-call/schedule-error/schedule-error.component';
import { SecureKeysProviderService } from './services/secure-keys-provider/secure-keys-provider.service';
import { CancelRescheduleCallComponent } from './schedule-a-call/cancel-reschedule-call/cancel-reschedule-call.component';
import { ScheduleCommonComponent } from './schedule-a-call/schedule-common/schedule-common/schedule-common.component';

export function propertyLoader(envConfigService: EnvConfigProviderService, secureKeysProviderService: SecureKeysProviderService): any {
  return () => envConfigService.init().then(() => secureKeysProviderService.init());
}

@NgModule({
  declarations: [
    AppComponent,
    PhoneMaskDirective,
    SetFocusDirective,
    ScheduleErrorComponent,
    CancelRescheduleCallComponent,
    ScheduleCommonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BoltDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: propertyLoader,
      deps: [ EnvConfigProviderService, SecureKeysProviderService ],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpSecurityInterceptor, multi: true },
    DatePipe,
    ContextCacheProviderService,
    SchduleACallResolver,
    CalendarHelper,
    WINDOW_PROVIDERS,
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateFormatter }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
