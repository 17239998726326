import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheProviderService {

  private cache: { [key: string]: any} = {};

    add(key: string, obj: any): void {
        this.cache[key] = obj;
    }

    get(key: string): any {
        if (this.cache[key]) {
            return this.cache[key];
        } else {
            return {};
        }
    }

    clean(): void {
        this.cache = {};
    }
}
