import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { v4 as uuidv4} from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { BaseServiceProvider } from '../base-service-provider';
import { AppConstants } from '../../app-constants';

@Injectable({
  providedIn: 'root'
})
export class BusinessClosedDatesProviderService extends BaseServiceProvider {
  private businessClosedDatesProviderUrl;
  private businessClosedDatesProviderClientId;

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
      super(messageProvider);
      const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
      const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
      this.businessClosedDatesProviderUrl = envConfig.businessClosedDatesProviderUrl;
      this.businessClosedDatesProviderClientId = secureKeys.apiClientId;
    }

  getBusinessClosedDates(period: string): Observable<[]> {
    const headers = new HttpHeaders()
                  .set('Content-Type', 'application/json')
                  .set('client_id', this.businessClosedDatesProviderClientId)
                  .set('X-NW-Message-ID', 'SCH-CALL-BH-' + uuidv4());

    return this.http.get<any>(this.businessClosedDatesProviderUrl.replace(/(\$\w+?\$)/g, period), {headers}).pipe(
      map(payload => {
        return payload.response;
      }),
      catchError(this.handleError<[]>('getBusinessClosedDates', []))
    );
  }

}
