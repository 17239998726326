import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberFormat {

  getStandardFormat(phoneNumber: string): string {
    if (phoneNumber.length === 0) {
      phoneNumber = '';
    } else if (phoneNumber.length <= 3) {
      phoneNumber = phoneNumber.replace(/^(\d{0,3})/, '($1)');
    } else if (phoneNumber.length <= 6) {
      phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (phoneNumber.length <= 10) {
      phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      phoneNumber = phoneNumber.substring(0, 10);
      phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return phoneNumber;
  }
}
