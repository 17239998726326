
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BusinessHours } from '../../services/business-hours-provider/business-hours';
import { SchedulingWindow } from '../constants/SchedulingWindow';

@Injectable({
  providedIn: 'root'
})
export class  CalendarHelper {

  constructor(private datePipe: DatePipe) {}

  public getMilTime(date: Date): number {
    const currentHour = date.getHours(); // strart hour
    const currentMinute = date.getMinutes();
    return currentHour * 100 + currentMinute;
  }

  public convertToMilTime(strDate: string): number {
    return parseInt(strDate.replace(':', ''), 10);
  }

  public ngbDateToDate(ngbDate: any): Date {
    const month = parseInt(ngbDate.month, 10) - 1; // months are 0-based
    const now = new Date();
    now.setDate(ngbDate.day);
    now.setMonth(month);
    now.setFullYear(ngbDate.year);
    return this.toUSEasternTime(now);
  }

  public isToday(date: Date): boolean {
    const now = new Date();
    return (date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear());
  }

  public isTodayOrFuture(date: any): boolean {
    if (date) {
      // date = {year: 2021, month: 8, day: 2}
      const selected = this.parseDate(date.year, date.month, date.day);
      const now = new Date();
      const today = this.parseDate(now.getFullYear(), (now.getMonth() + 1), now.getDate());
      return selected >= today;
    }
    return false;
  }

  public parseDate(year: number, month: number, day: number) {
    return Date.parse(year + "-" + month + "-" + day);
  }

  public convertToStringDate(ngbDate: any): string {
    const scheduledTime = this.ngbDateToDate(ngbDate);
    return '' + this.datePipe.transform(scheduledTime, 'yyyy-MM-dd');
  }

  public toUSEasternTime(date: Date): Date {
    const dt = new Date(date.getTime()); // generate the date in uesrs timezone eg:- Wed Jul 21 2021 12:54:15 GMT-0400 (Eastern Daylight Time)
    dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000); // convert to GMT by adding offset for the timezone
    const offset = DateTime.local().isInDST ? -240 : -300; // Timezone offset for Eastern Time in minutes.
    return new Date(dt.getTime() + offset * 60 * 1000); // convert to Eatern Time and return
  }

  toHhMmSs(hmsStr: string): any {
    const timeTokens = hmsStr.split(':');
    return {
      hour: parseInt(timeTokens[0], 10),
      minute: parseInt(timeTokens[1], 10),
      seconds: parseInt(timeTokens[2], 10)
    };
  }

  public getCallingTimeSlots(dateSelected: any, businessHours: BusinessHours[]): any {
    const scheduledDate = this.ngbDateToDate(dateSelected);
    // Date evaluate day as Sunday = 0, Monday = 1, ..., Saturday = 6
    // BusinessHoursProviderService evaluates day as Sunday = 1, Monday = 2, ..., Saturday = 7
    // hence adding 1 for comparison
    const myDayOfWeek = scheduledDate.getDay() + 1;
    if (myDayOfWeek > 1) {
      const businessHours4Day = businessHours.filter(k => parseInt(k.dayOfWeek, 10) === myDayOfWeek)[0];
      const openTimeMil = this.convertToMilTime(businessHours4Day.openTime);
      const closeTimeMil = this.convertToMilTime(businessHours4Day.closeTime);
      if (this.isToday(scheduledDate)) {
        const milTimeNow = this.getMilTime(this.toUSEasternTime(new Date()));
        const businessOpenTime = milTimeNow > openTimeMil ? milTimeNow : openTimeMil;
        return SchedulingWindow.SLOTS.filter(i => i.key > businessOpenTime && i.key < closeTimeMil);
      } else {
        return SchedulingWindow.SLOTS.filter(i => i.key >= openTimeMil && i.key < closeTimeMil);
      }
    } else {
      return [];
    }
  }

  public dateTimeStringToDateNTime(scheduledTime: string): any {
    // 'scheduledTime': '2021-07-08T14:54:21-04:00',
    const dt = new Date(scheduledTime);
    const hr = dt.getHours();
    const ap = hr >= 12 ? 'pm' : 'am';
    let hour =  hr % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    const minutes = dt.getMinutes();
    const min = minutes < 10 ? '0' + minutes : minutes;
    return {
      date: (dt.getMonth() + 1) + '/' + dt.getDate() + '/' + dt.getFullYear(),
      time: hour + ':' + min + ':00',
      ampm: ap,
      zone: 'ET'
    };
  }

}
