import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneNumberFormat } from '../../../shared/formatters/phone-number-format';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../../app-constants';
import { SchedulingWindow } from '../../../shared/constants/SchedulingWindow';
import { CalendarHelper } from '../../../shared/utils/calendar-helper';
import { EmailValidator } from '../../../shared/validators/email-validator';
import { InputDateValidator } from '../../../shared/validators/input-date-validator';
import { NameValidator } from '../../../shared/validators/name-validator';
import { PolicyNumberValidator } from '../../../shared/validators/policy-number-validator';
import { BusinessCalendarProviderService } from '../../../services/business-calendar-provider/business-calendar-provider.service';
import { BusinessClosedDatesProviderService } from '../../../services/business-closed-dates-provider/business-closed-dates-provider.service';
import { BusinessHoursProviderService } from '../../../services/business-hours-provider/business-hours-provider.service';
import { EmailProviderService } from '../../../services/email-provider/email-provider.service';
import { MessageProviderService } from '../../../services/message-provider/message-provider.service';
import { ExecutionPhaseProviderService } from '../../../services/execution-phase-provider/execution-phase-provider.service';
import { ScheduleCallbackProviderService } from '../../../services/schedule-callback-provider/schedule-callback-provider.service';
import { StateDataProviderService } from '../../../services/state-data-provider/state-data-provider.service';
import { UserConfig } from '../../../services/user-config-provider/user-config';
import { BusinessHours } from '../../../services/business-hours-provider/business-hours';
import { ScheduleCallbackResponse } from '../../../services/schedule-callback-provider/schedule-callback-response';
import { ScheduleCallRequest as ScheduleCallRequest } from '../../../services/schedule-callback-provider/schedule-a-call-request';
import { ScheduleACallApiErrorResponse } from '../../../services/schedule-callback-provider/schedule-a-call-api-error-response';
import { ScheduleBaseComponent } from '../../schedule-base/schedule-base.component';

@Component({
  selector: 'app-schedule-common',
  templateUrl: './schedule-common.component.html',
  styleUrls: ['./schedule-common.component.scss']
})
export class ScheduleCommonComponent extends ScheduleBaseComponent implements OnInit {

  callWhenSelection = AppConstants.CALL_WHEN_SELECTION;
  timeIntervals = SchedulingWindow.SLOTS;
  callShedulePeriod = SchedulingWindow.PERIOD; // no. of days in future
  usStates = AppConstants.US_STATES;
  disclaimer:string = '';
  disclaimerAllowed = false;
  businessHours: BusinessHours[] = [];
  businessClosedDates: [] = [];
  selectedTimeDisplay = AppConstants.BLANK;
  callmeNow = true;
  calendarProvider: any;
  callScheduledSuccessfully = false;
  scheduleServiceCalled = false;
  policyNumberRequired = false;
  collapsibleElement = false;
  operateDuringBusinessHours = false;
  callMeHours: any[] =[];
  callScheduleStyleClass: string = 'call-me-later';

  callScheduleForm: FormGroup;
  appConfig: any;
  messages: any = [];

  emailInputAllowed: boolean = false;
  businessNameInputAllowed: boolean = false;
  policyNumberInputAllowed: boolean = false;
  stateInputAllowed: boolean = false;

  constructor(public formBuilder: FormBuilder,
              private phoneNumberFormat: PhoneNumberFormat,
              public calendar: NgbCalendar,
              public datePipe: DatePipe,
              public calendarHelper: CalendarHelper,
              public router: Router,
              public route: ActivatedRoute,
              public injector: Injector) {
    super(AppConstants.EXECUTION_PHASES.SCHEDULE);
    this.callScheduleForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    const data = this.route.snapshot.data.contextInfo;
    this.appConfig = data.actx;
    const appId = window.sessionStorage.getItem(AppConstants.APPLICATION_ID) ?? '';
    this.disclaimer = AppConstants.DISCAILMERS[appId] ?? AppConstants.DISCAILMERS['default'];
    this.setPageHeaderAndTitle(this.executionPhase.init);
    this.processUserConfigurations(data.uctx);
    this.addAllFormControls();
    this.getBusinessHours4Application(this.appConfig.callType);
    this.handleCallNowLaterRadioSelections();
  }

  private processUserConfigurations(userConfig: UserConfig[]): void {
    userConfig.forEach(element => {
      if (element.key === 'emailAddress') {
        if(element.value === 'MASKED') {
          this.emailInputAllowed = false;
        } else {
          this.emailInputAllowed = true;
        }
      } else if (element.key === 'businessName' && element.value == 'YES') {
        this.businessNameInputAllowed = true;
      } else if (element.key === 'policyNumber' && element.value == 'YES') {
        this.policyNumberInputAllowed = true;
      } else if (element.key === 'state' && element.value == 'YES') {
        this.stateInputAllowed = true;
      }
    });
  }

  private addAllFormControls() {
    let rescheduledData = this.getRescheduleData();
    const formattedPhoneNumber = this.phoneNumberFormat.getStandardFormat(rescheduledData.phoneNumber);
    // adding base controls to the form
    this.callScheduleForm = this.formBuilder.group({
      firstName: [rescheduledData.firstName, [Validators.required, NameValidator.content()]],
      lastName: [rescheduledData.lastName, [Validators.required, NameValidator.content()]],
      phoneNumber: [formattedPhoneNumber, [Validators.required, Validators.pattern(AppConstants.REGEX.PHONENUMBER)]],
      callWhen: ['CALL_NOW'],
    });
    this.addDynamicFormControls(rescheduledData);
  }

  private getRescheduleData() {
    let rescheduledData = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      businessName: '',
      accountNumber: '',
      state: ''
    }
    let duplicateCallData = window.sessionStorage.getItem(AppConstants.DUPLICATE_CALL_DATA);
    if (duplicateCallData !== null) {
      this.injector.get<ExecutionPhaseProviderService>(ExecutionPhaseProviderService).addPhase(AppConstants.EXECUTION_PHASES.RESCHEDULE);
      this.executionPhase = AppConstants.EXECUTION_PHASES.RESCHEDULE;
      this.setPageHeaderAndTitle(this.executionPhase.init);
      let prevCallData = JSON.parse(duplicateCallData);
      rescheduledData.firstName = prevCallData['firstName'] || '';
      rescheduledData.lastName = prevCallData['lastName'] || '';
      rescheduledData.phoneNumber = prevCallData['phoneNumber'] || '';
      rescheduledData.email = prevCallData['emailAddress'] || '';
      rescheduledData.businessName =  prevCallData['businessName'] || '';
      rescheduledData.accountNumber =  prevCallData['accountNumber'] || '';
      rescheduledData.state =  prevCallData['stateId'] || '';
    }
    return rescheduledData;
  }

  private addDynamicFormControls(rescheduledData: any) {
    if (this.emailInputAllowed) {
      this.callScheduleForm.addControl('email', new FormControl(rescheduledData.email, [EmailValidator.content()]));
    }
    if (this.businessNameInputAllowed) {
      this.callScheduleForm.addControl('businessName', new FormControl(rescheduledData.businessName, []));
    }
    if (this.policyNumberInputAllowed) {
      this.callScheduleForm.addControl('policyNumber', new FormControl(rescheduledData.accountNumber, [PolicyNumberValidator.content()]));
    }
    if (this.stateInputAllowed) {
      this.callScheduleForm.addControl('state', new FormControl(rescheduledData.state, [Validators.required, Validators.pattern(AppConstants.REGEX.STATECODE)]));
    }
  }

  private getBusinessHours4Application(callType: string): void {
    this.getBusinessHoursAndClosedDates(callType).pipe(
      map(result => {
        this.businessHours = result[0];
        this.businessClosedDates = result[1];
        this.callMeHours = this.buildCallMeNowHours(result[0]);
        this.calendarProvider =  this.injector.get<BusinessCalendarProviderService>(BusinessCalendarProviderService).getBusinessCalendar(this.businessHours, this.businessClosedDates);
        this.disableImmediateCallbackDuringNonWorkingHours();
      })
    ).subscribe();
  }

  private getBusinessHoursAndClosedDates(callType: string): Observable<any> {
    return forkJoin([
      this.injector.get<BusinessHoursProviderService>(BusinessHoursProviderService).getBusinessHours4Application(callType),
      this.injector.get<BusinessClosedDatesProviderService>(BusinessClosedDatesProviderService).getBusinessClosedDates(AppConstants.DEFAULT_CAL_PERIOD)
    ]);
  }

  public buildCallMeNowHours(bussHours: BusinessHours[]): any[] {
    const daysByWorkingHours: {[key: string] : number[]} = {};
    for (let bh of bussHours) {
      const openTime = bh.openTime == null ? '0:00' : bh.openTime;
      const closeTime = bh.closeTime == null ? '0:00' : bh.closeTime;
      const workingHours: string = this.milToStd(openTime).concat(' - ').concat(this.milToStd(closeTime));
      let dbwh: number[] = daysByWorkingHours[workingHours];
      if (dbwh === undefined || !dbwh.length) {
        dbwh = [];
      }
      const dow = +bh.dayOfWeek;
      dbwh.push(dow);
      daysByWorkingHours[workingHours] = dbwh;
    }

    let returnCallMeHours: any = [];
    Object.keys(daysByWorkingHours).forEach(key => {
      const days: number[] = daysByWorkingHours[key];
      const d1 = this.getDaysDisplayLabel(days[0]);
      const workingHours = (key === '0:00 a.m. - 0:00 a.m.') ? 'Closed' : key;
      if (days.length == 1) {
        returnCallMeHours.push({ days: d1, hours: workingHours});
      } else if (days.length == 2) {
        const d2 =this.getDaysDisplayLabel(days[days.length - 1]);
        returnCallMeHours.push({ days: d1 + ', ' + d2, hours: workingHours});
      } else if (days.length > 1) {
        const d2 =this.getDaysDisplayLabel(days[days.length - 1]);
        returnCallMeHours.push({ days: d1 + ' - ' + d2, hours: workingHours});
      }
    });
    return returnCallMeHours;
  }

  public disableImmediateCallbackDuringNonWorkingHours(): void {
    const now = new Date();
    const _dayOfWeek = now.getDay(); // day of week is 0 based
    const milTimeNow = this.calendarHelper.getMilTime(this.calendarHelper.toUSEasternTime(now));
    const businessHours4Today = this.businessHours.filter(i => parseInt(i.dayOfWeek) === (_dayOfWeek + 1))[0];
    const openTime = businessHours4Today.openTime;
    const closeTime = businessHours4Today.closeTime
    if (openTime != null && closeTime != null) {
      const openTimeMil = this.calendarHelper.convertToMilTime(openTime);
      const closeTimeMil = this.calendarHelper.convertToMilTime(closeTime);
      if (openTimeMil <= milTimeNow && milTimeNow <= closeTimeMil) {
        this.callScheduleForm.get('callWhen')?.setValue('CALL_NOW');
        this.operateDuringBusinessHours = true;
      } else {
        // off hours
        this.displayDateTimeSelection4OffHoursAndHolidays();
      }
    } else {
      // holidays
      this.displayDateTimeSelection4OffHoursAndHolidays();
    }
  }

  private displayDateTimeSelection4OffHoursAndHolidays(): void {
    this.addSchedulingControls()
    this.callScheduleForm.get('callWhen')?.setValue('CALL_LATER');
    this.operateDuringBusinessHours = false;
    this.callScheduleStyleClass = '';
  }

  public handleCallNowLaterRadioSelections(): void {
    this.callScheduleForm.get('callWhen')?.valueChanges.subscribe(val => {
      if (val === 'CALL_NOW') {
        this.callScheduleForm.removeControl('scheduledTime');
        this.callScheduleForm.removeControl('scheduledDate');
        this.callmeNow = true;
        this.collapsibleElement = false;
      } else {
        this.addSchedulingControls();
        this.collapsibleElement = true;
      }
    });
  }

  private addSchedulingControls(): void {
    this.callScheduleForm.addControl('scheduledTime', new FormControl({value: '', disabled: true}, Validators.required));
    this.callScheduleForm.addControl('scheduledDate', new FormControl('', InputDateValidator.content()));
    // subscribe to date picker change event
    this.callScheduleForm.get('scheduledDate')?.valueChanges.subscribe(valTmp => {
      this.callScheduleForm.get('scheduledTime')?.enable();
      this.callScheduleForm.get('scheduledTime')?.setValue('');
      if (this.calendarHelper.isTodayOrFuture(valTmp)) {
        this.timeIntervals = this. calendarHelper.getCallingTimeSlots(valTmp, this.businessHours);
      }
    });
    this.callmeNow = false;
  }

  public cancel(): void {
    window.close();
  }

  public schedule(): void {
    if (this.callScheduleForm.valid) {
      const request = this.constructRequest();
      this.callScheduledSuccessfully = false;
      if (this.policyNumberRequired && !request.accountNumber) {
        this.messages.push({text: AppConstants.MESSAGES.POLICY_NOT_FOUND, type: 'error'});
      } else {
        this.injector.get<ScheduleCallbackProviderService>(ScheduleCallbackProviderService).scheduleCallback(request).subscribe(data => {
          this.scheduleServiceCalled = true;
          if (this.messages[0]) {
            this.messages = [];
          }
          if (data.severity === 'success') {
            this.handleSuccessOrReshedule(data);
          } else {
            this.messages.push(this.injector.get<MessageProviderService>(MessageProviderService).getCallScheduleErrorMessage(data.response as ScheduleACallApiErrorResponse));
          }
          window.sessionStorage.removeItem(AppConstants.DUPLICATE_CALL_DATA);
        });
      }
    } else {
      this.messages = [];
      this.messages.push({text: AppConstants.MESSAGES.FILL_ALL_REQUIRED_FIELDS, type: AppConstants.ERROR});
    }
  }

  private handleSuccessOrReshedule(data: any) {
    this.callScheduledSuccessfully = true;
    const callbackResponse: ScheduleCallbackResponse = data.response as ScheduleCallbackResponse;
    if (callbackResponse.duplicateRecordId) { // there is an already scheduled call
      this.injector.get<StateDataProviderService>(StateDataProviderService).addData(callbackResponse);
      this.injector.get<ExecutionPhaseProviderService>(ExecutionPhaseProviderService).addPhase(AppConstants.EXECUTION_PHASES.CANCEL);
      this.router.navigate(['/c2cb/cancel-reschedule']);
    } else { // schedule successful
      this.setPageHeaderAndTitle(this.executionPhase.complete);
      this.sendAckEmailIfAllowed(callbackResponse);
      this.messages.push(this.injector.get<MessageProviderService>(MessageProviderService).getCallScheduleSuccessMessage(callbackResponse));
      this.injector.get<ExecutionPhaseProviderService>(ExecutionPhaseProviderService).addPhase(AppConstants.EXECUTION_PHASES.SCHEDULE);
    }
  }

  private constructRequest(): ScheduleCallRequest {
    const callDateTime = this.getCallDateAndTime();
    return {
      phoneNumber: this.getSanitizedPhoneNumber(),
      scheduledTime: callDateTime ? callDateTime : undefined,
      quoteId: '',
      callType: this.appConfig.callType,
      clientReferenceId: this.generateClientReferenceId(),
      firstName: this.callScheduleForm.get('firstName')?.value.trim(),
      lastName: this.callScheduleForm.get('lastName')?.value.trim(),
      channel: window.sessionStorage.getItem(AppConstants.APPLICATION_ID) || '',
      stateId: this.getStateId(),
      agencyName: '',
      accountNumber:this.callScheduleForm.get('policyNumber')?.value.trim(),
      emailAddress: this.callScheduleForm.get('email')?.value.trim() || '',
      businessName: this.callScheduleForm.get('businessName')?.value.trim()
    };
  }

  private getEmailRequest(scheduledIime: string, emailAddress: string): any {
    const dateTime  = this.datePipe.transform(new Date(scheduledIime), 'M/d/yyyy,h:mm a');
    let contentDate = '';
    let contentTime = '';
    const contentDateTime = dateTime?.split(',');
    if (contentDateTime !== undefined) {
      contentDate = contentDateTime[0];
      contentTime = contentDateTime[1];
    }

    return {
      toAddress: [emailAddress],
      copyAddress: [],
      blindCopyAddress: [],
      fromAddress: AppConstants.EMAIL.FROM.ADDRESS,
      fromName: AppConstants.EMAIL.FROM.NAME,
      template: {
        templateName: this.appConfig.ackEmailTemplate,
        requesterName: AppConstants.EMAIL.REQUESTOR,
        templateContent: [
          { name: 'firstName', value: this.callScheduleForm.get('firstName')?.value.trim() },
          { name: 'date', value: contentDate },
          { name: 'time', value: contentTime + ' ET' },
          { name: 'messageBody', value: AppConstants.EMAIL.MESSAGE_BODY },
          { name: 'tollFreeNumber', value: AppConstants.EMAIL.TOLL_FREE_NUMBER }
        ]
      }
    };
  }

  private sendAckEmailIfAllowed(apiResponse: ScheduleCallbackResponse): void {
    let emailService = this.injector.get<EmailProviderService>(EmailProviderService);
    if (this.appConfig.ackEmailEnabled && apiResponse.emailAddress) {
      emailService.sendNotification(this.getEmailRequest(apiResponse.scheduledTime, apiResponse.emailAddress)).subscribe();
    }
  }

  private getSanitizedPhoneNumber(): string {
    return this.callScheduleForm.get('phoneNumber')?.value.replace(/[^\d]/g, '');
  }

  private getCallDateAndTime(): string {
    const callTime = this.callScheduleForm.get('scheduledTime')?.value; // '10:00:00'
    const callDate = this.callScheduleForm.get('scheduledDate')?.value; // {year: 2021, month: 6, day: 22}
    // new Date(); will create date-time for the timezone in which machine this code being executed.
    // eg:- Wed Jul 27 2021 10:53:25 GMT-0400 (Eastern Daylight Time) or
    //      Tue Jul 27 2021 07:53:25 GMT-0700 (Pacific Daylight Time)
    let scheduledDate: Date = new Date();
    if (callTime && callDate) {
      const scheduledTime = this.calendarHelper.toHhMmSs(callTime);
      scheduledDate = this.calendarHelper.ngbDateToDate(callDate);
      // now we recreate ET as we use hh, mm, ss, selected from
      // a list which display ET time
      scheduledDate.setHours(scheduledTime.hour, scheduledTime.minute, scheduledTime.seconds);
      return this.datePipe.transform(scheduledDate, 'YYYY-MM-ddTHH:mm:ssZZZZZ') || '';
    } else {
      return '';
    }
  }

  private generateClientReferenceId(): string {
    return (new Date().getTime()).toString().slice(AppConstants.CLIENT_REF_ID_LENGTH * -1);
  }

  private getStateId(): string {
    const stateCode = this.callScheduleForm.get('state')?.value;
    return stateCode ? stateCode : AppConstants.DEFAULT_STATE_CODE;
  }

  public onDateChange(): void {
    this.selectedTimeDisplay = AppConstants.BLANK;
    const dateEntered = this.callScheduleForm.get('scheduledDate')?.value;
    if (dateEntered && this.calendarHelper.isTodayOrFuture(dateEntered)) {
      this.callScheduleForm.get('scheduledTime')?.setValue('');
      this.timeIntervals = this.calendarHelper.getCallingTimeSlots(dateEntered, this.businessHours);
    } else {
      this.callScheduleForm.get('scheduledTime')?.disable();
    }
  }

  public onCallTimeChange(): void {
    const time = this.callScheduleForm.get('scheduledTime')?.value;
    this.selectedTimeDisplay = SchedulingWindow.SLOTS.filter(i => i.time === time)[0].interval;
  }

  public dateCharsOnly(event: any): boolean {
    const k = event.charCode;
    // allow user to enter only digits and forward slash
    return (k >= 48 && k <= 57) || ( k === 47 || k === 191);
  }

  private milToStd(time: string): string {
    let timeStr = '';
    const hm = time.split(":");
    const h = +hm[0];
    if (h > 12) {
      timeStr = h%12 + ':' + hm[1] + ' p.m.';
    } else {
      timeStr = h + ':' + hm[1] + ' a.m.';
    }
    return timeStr;
  }

  private getDaysDisplayLabel(dayOfWeek: number): string {
    return AppConstants.WEEK_DAYS.filter(i => i.dayOfWeek == dayOfWeek)[0].day.substring(0,3);
  }

}
