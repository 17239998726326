import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { AppConstants } from '../app-constants';
import { AppConfigProviderService } from '../services/app-config-provider/app-config-provider.service';
import { UserConfigProviderService } from '../services/user-config-provider/user-config-provider.service';
import { map } from 'rxjs/operators';
@Injectable()
export class SchduleACallResolver implements Resolve<any> {

  constructor(private userConfigProvider: UserConfigProviderService,
              private appConfigProvider: AppConfigProviderService) {}

  resolve(route: ActivatedRouteSnapshot): object {
    const fragment = route.fragment;
    if (fragment) {
      const params = new URLSearchParams(fragment);
      const appId = this.getValidatedQueryParamter(params, 'appId', AppConstants.REGEX.APP_ID);
      window.sessionStorage.setItem(AppConstants.APPLICATION_ID, appId);
      return forkJoin([
        this.userConfigProvider.getUserConfigAttributes(appId),
        this.appConfigProvider.getAppConfigAttributes(appId)
      ]).pipe(map(result => {
        return {
          uctx: result[0],
          actx: result[1]
        };
      }));
    } else {
      return of({
        uctx: {},
        actx: {}
      });
    }
  }


  private getValidatedQueryParamter(params: URLSearchParams, key: string, regex: RegExp) {
    let value = params.get(key) || '';
    value = regex.test(value) ? value : '';
    return value;
  }
}
