import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[setFocusOnError]',
})
export class SetFocusDirective {
  constructor(public elementRef: ElementRef) {}

  @HostListener('submit')
  onFormSubmit(): void {
    const invalidControl = this.elementRef.nativeElement.querySelector('.ng-invalid');
    if (invalidControl) {
      invalidControl.focus();
    }
  }
}

