import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomNgbDateFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { month: this.toInteger(dateParts[0]), day: 0, year: 0 };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return {
          month: this.toInteger(dateParts[0]),
          day: this.toInteger(dateParts[1]),
          year: 0
        };
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return {
          month: this.toInteger(dateParts[0]),
          day: this.toInteger(dateParts[1]),
          year: this.toInteger(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      const day = this.isNumber(date.day) ? this.padNumber(date.day) : '';
      const month = this.isNumber(date.month) ? this.padNumber(date.month) : '';
      const year = date.year;
      return `${month}/${day}/${year}`;
    }
    return '';
  }

  public toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  public isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  public padNumber(value: number): string {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}
