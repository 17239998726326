import { AbstractControl, ValidatorFn } from '@angular/forms';
import { AppConstants } from '../../app-constants';

export class PolicyNumberValidator {
  static content(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const policyNumber = control.value;
      if (!control || policyNumber === '') {
        // empty value is valid as this is an optional field
        return null;
      }
      // if value exist and if it is invalid, send error object.
      // Otherwise send null to indicate it is valid
      const isValidPolicyNUmber =
        policyNumber.trim().length <= AppConstants.MAX_ACCOUNT_NUMBER_LENGTH &&
        (AppConstants.REGEX.PCI_POLICY_NUMBER.test(policyNumber) ||
        AppConstants.REGEX.NF_POLICY_NUMBER.test(policyNumber) ||
        AppConstants.REGEX.NF_CONTRACT_NUMBER.test(policyNumber) ||
        AppConstants.REGEX.SA_POLICY_NUMBER.test(policyNumber) ||
        AppConstants.REGEX.SP_POLICY_NUMBER.test(policyNumber));
      return !isValidPolicyNUmber ? {policyNumberInvalid: true} : null;
    };
  }
}
