import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4} from 'uuid';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { AppConstants } from '../../app-constants';
import { catchError, map } from 'rxjs/operators';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { ContextCacheResponse } from '../../schedule-a-call/model/context-cache-response';
import { ContextCacheError } from '../../schedule-a-call/model/context-cache-error';
import { Context } from '../../schedule-a-call/model/context';
import { BaseServiceProvider } from '../base-service-provider';


@Injectable({
  providedIn: 'root'
})
export class ContextCacheProviderService extends BaseServiceProvider {

  private contextCacheServiceUrl: string;
  private contextCacheApiClientId: string;

  contextError: ContextCacheError = {
    userMessage: '',
    developerMessage: '',
    messageID: '',
    code: '',
    source: ''
  };

  context: Context = {
    policyNumber: '',
    emailAddress: '',
    businessName: ''
  };

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              private messageProviderService: MessageProviderService) {
    super( messageProviderService);
    const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
    const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
    this.contextCacheServiceUrl = envConfig.contextCacheServiceUrl;
    this.contextCacheApiClientId = secureKeys.contextCacheApiClientId;
  }

  getByLinkMode(otp: string, contextId: string): Observable<ContextCacheResponse> {

    const headers = new HttpHeaders()
                    .set('client_id', this.contextCacheApiClientId)
                    .set('X-NW-Message-ID', 'SCH-CALL-CC-LM-' + uuidv4());

    const contextCacheLinkModeUrl = this.contextCacheServiceUrl.replace(/(\$contextId\$)/g, contextId).replace(/(\$otp\$)/g, otp);

    return this.http.get<any>(contextCacheLinkModeUrl, {headers}).pipe(
      map(payload => {
        if (payload.hasOwnProperty('context')) {
          return {severity: 'success', context: payload.context, error: this.contextError};
        } else {
          return {severity: 'error', context: this.context, error: payload};
        }
      }),
      catchError(this.handleErrorcc('getByLinkMode'))
    );
  }

  public handleErrorcc(operation = 'operation'): any {
    return (error: any): Observable<ContextCacheResponse> => {
      const errorJson = error.error;
      const errMsg = errorJson.code;
      if (errMsg !== undefined ) {
        this.messageProviderService.add(errMsg, AppConstants.ERROR);
        this.log(`${operation} failed: ${errMsg}`);
      }
      return of({severity: 'error', context: this.context,  error: errorJson});
    };
  }

}
