import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { AppConstants } from '../../app-constants';
import { ScheduleCallRequest } from './schedule-a-call-request';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4} from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { BaseServiceProvider } from '../base-service-provider';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { CommonResponseObject } from '../../schedule-a-call/model/common-response-object';


@Injectable({
  providedIn: 'root'
})
export class ScheduleCallbackProviderService extends BaseServiceProvider {

  private scheduleACallProviderServiceUrl: string;
  private scheduleACallProviderApiClientId: string;

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
      super(messageProvider);
      const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
      const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
      this.scheduleACallProviderServiceUrl = envConfig.scheduleCallbackServiceUrl;
      this.scheduleACallProviderApiClientId = secureKeys.apiClientId;
  }

  scheduleCallback(scheduleACallRequest: ScheduleCallRequest): Observable<any> {
    const headers = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('client_id', this.scheduleACallProviderApiClientId)
                    .set('X-NW-Message-ID', 'SCH-CALL-CH' + uuidv4());
    const options = { headers };

    return this.http.post<CommonResponseObject>(this.scheduleACallProviderServiceUrl, scheduleACallRequest, options).pipe(
      map(payload => {
        return payload
      }),
      catchError(this.handleError<Observable<CommonResponseObject>>('scheduleCall', this.errorResponse()))
    );
  }

  private errorResponse(): Observable<CommonResponseObject> {

    return of(
      { severity: "error",
        code: "9999",
        message: "Error retrieving data from service",
        timestamp: new Date().toString(),
        response: {
          recordId: 0,
          phoneNumber: '',
          phoneExt: 0,
          scheduledTime: '',
          applicationId: '',
          quoteId: '',
          callType: '',
          clientReferenceId: '',
          firstName: '',
          lastName: '',
          channel: '',
          stateId: '',
          agencyName: '',
          accountNumber: '',
          emailAddress: '',
          businessName: '',
          comments: '',
          duplicateRecordId: '',
          duplicateScheduledTime: '',
          duplicatePhoneNumber: ''
        }
      }
    );
  }

}
