import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EnterpriseAuthProviderService } from '../services/enterprise-auth-provider/enterprise-auth-provider.service';

@Injectable()
export class HttpSecurityInterceptor implements HttpInterceptor {

  private cache: Map<string, HttpResponse<any>> = new Map();

  constructor(private authProvider: EnterpriseAuthProviderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('customer-assistance/click-to-callback/v1/schedule-callbacks') >= 0 ||
        req.url.indexOf('customer-assistance/click-to-callback/v1/acknowledge-email-sends') >= 0 ||
        this.isCancelUrl(req.url)) {
      return this.authProvider.getAuth4SystemRealm().pipe(
        switchMap(auth => {
          const newRequest = req.clone({
            setHeaders: this.newRequest(auth.accessToken)
          });
          return this.handleRequest(newRequest, next);
        })
      );
    } else {
      return this.handleRequest(req, next);
    }
  }

  private isCancelUrl(url: string) {
    const match = url.match(/customer-assistance\/click-to-callback\/v1\/calls\/\d{1,6}\/cancels$/);
    return match !== null ? url.indexOf(match[0]) : false;
  }

  private newRequest(token: string): any {
    return {
      Authorization: `Bearer ${token}`
    };
  }

  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    if(req.method !== "GET") {
        return next.handle(req)
    }
    const url = req.url;
    const resource = url.substring(url.lastIndexOf('v1') + 2);
    const cachedResponse: any = this.cache.get(url)
    if(cachedResponse) {
      console.log('retrieving ' + resource + ' from cache');
      return of(cachedResponse.clone())
    } else {
      console.log('retrieving ' + resource + ' from service');
      return next.handle(req).pipe(
        map(response => {
          if (response instanceof HttpResponse) {
              this.cache.set(url, response.clone())
          }
          return response;
        })
      );
    }
  }
}