import { AbstractControl, ValidatorFn } from '@angular/forms';
import { AppConstants } from '../../app-constants';

export class NameValidator {
  static content(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const name = control.value.trim();
      if (name && AppConstants.REGEX.NAME.test(name)) {
        return null; // valid
      } else {
        return {nameInvalid: true};
      }
    };
  }
}
