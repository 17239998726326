export class AppConstants {
    public static MODAL_TITLE = 'Tell us how to reach you';

    public static SUCCESSFUL = 'SUCCESSFUL';

    public static SUCCESS = 'success';

    public static ERROR = 'error';

    public static INFO = 'info';

    public static REDIRECT = 302;

    public static BLANK = '';

    public static DISALLOWED_ROUTE = "disallowed-route";

    public static CALL_WHEN_SELECTION = [
        {id: 'radio4', val: 'CALL_NOW', selected: true, text: 'Call me as soon as you can'},
        {id: 'radio5', val: 'CALL_LATER', selected: false, text: 'Call me later'}
    ];

    public static MESSAGES = {
        // tslint:disable-next-line:max-line-length
        ACCOUNT_NOT_FOUND: 'Details for this account are currently unavailable in Navigate Nationwide. Please try again later or launch Agent Center.',
        SERVICE_IS_DOWN: 'This feature is temporarily unavailable. Please try again later or launch Agent Center.',
        INCOMPLETE_REQUIRED_FIELDS: 'Please complete all required fields before proceeding.',
        // tslint:disable-next-line:max-line-length
        CALL_SCHEDULE_SUCCESS: 'Thank you for contacting Nationwide. We\'re sending your request to the Nationwide professional who can best assist you.',
        // tslint:disable-next-line:max-line-length
        AGENT_CENTER_LAUNCH_FAILURE: 'This feature is temporarily unavailable. Please try again later or call the service center at 1-800-282-1446.',
        AGENT_UNAUTHORIZED_ACCESS: 'You do not have permission to see billing accounts that do not belong to your agency.',
        // tslint:disable-next-line:max-line-length
        DEFAULT: 'Details for this account are currently unavailable in Navigate Nationwide. Please try again later or launch Agent Center.',
        ERROR_ROUTE: 'Access unauthorized! Please contact Nationwide.',
        // tslint:disable-next-line:max-line-length
        EXTERNAL_REROUT_ERROR: 'We are unable to process your request. Contact your local Agent or call 855-241-8965. Representatives are available Monday through Friday from 8AM to 9PM EST.',
        //////
        CALL_SUCCESSFULLY_SCHEDULED: 'A Nationwide professional will call ${firstName} ${lastName} at &{phoneNumber} on ${date} ${time}',
        POLICY_NOT_FOUND: 'This policy is not found. Please try again later or contact Nationwide',
        FILL_ALL_REQUIRED_FIELDS: 'Please provide valid information for all required fields before you can schedule a call.',
        OTP_MULTIPLE_USE_ERROR: 'Sorry for the inconvenience, but it appears you may have already used the one-time link in your email. Please call 888-508-8622 to talk to a representative about your policy renewal.',
        CANCEL_SUCCESS: 'You have successfully cancelled your call. Thank you for contacting Nationwide.',
        OTP_EXPIRED_MESSAGE: 'Request OTP could not be verified'
    };

    public static CALLS_CUTOFF_TIME = 2145; // Mil time for 7:45pm

    public static DYNAMIC_ENV_CONFIG = 'dynamicEnvConfig';

    public static SECURE_KEYS = 'secureKeys';


    public static REGEX = {
        NAME: /^[a-zA-Z']{1,100}$/,
        UUID : /^[0-9a-zA-Z]{32}$/,
        STATECODE: /^[A-Z]{2}$/,
        PHONENUMBER: /^(\(\d{3}\)\s|\d{3}-)\d{3}-\d{4}$/,
        ZIPCODE : /^\d{5}$/,
        PCI_POLICY_NUMBER: /^\d{4}([a-zA-Z]{2}|[a-zA-Z]{1}\\s)\d{6}$/, // 1234AB123456 or 1234A 123456.
        NF_POLICY_NUMBER: /^\d{5,11}$/, // 1234AB123456 or 1234A 123456
        NF_CONTRACT_NUMBER: /^\d{5,11}[0-9a-zA-Z\\-]{0,11}$/, // 1234567-457 or 1234567-OBRA-PST
        SA_POLICY_NUMBER: /^([a-zA-Z]{4}|[a-zA-Z]{3}\\s)\d{10}$/, // ABCD1234567890 or ABC1234567890
        SP_POLICY_NUMBER: /^([a-zA-Z]{4}|[a-zA-Z]{3}\\s)([a-zA-Z]{4}|[a-zA-Z]{3}\\s|[a-zA-Z]{2}\\s{2})\d{10}$/, // ABC DEFG1234567890 or ABC DEF 1234567890 or ABC DE  1234567890 or ABCDEFG 1234567890 or ABCDEFGH1234567890 or ABCDEF  1234567890
        CONTEXT_ID: /^[a-zA-Z0-9]{32}$/,
        OTP: /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
        DATE: /^(0[1-9]|1[012])[\/.](0[1-9]|[12]\d|3[01])[\/.](19|20)\d\d$/,
        APP_ID: /^comm_svc_plus$|^nss_comm_sales$|^nf_life_insurance$|^nss_personal_sales$/,
        FROM_URL: /^\/c2cb\/schedule#appId=(comm_svc_plus|nss_comm_sales|nf_life_insurance|nss_personal_sales)$/
    };

    public static MAX_ACCOUNT_NUMBER_LENGTH = 18;

    public static DEFAULT_DATE = {
      year: 0,
      month: 0,
      day: 0
    };

    public static SATURDAY = 7;
    public static SUNDAY = 1;

    public static APPLICATION_ID = 'application-id';

    public static DUPLICATE_CALL_DATA = 'duplicate-call-data';

    public static CONTEXT_CACHE = 'cache-context';

    public static CUSTOMER_SERVICE_NUMBER = {
      text: '1-877 On Your Side',
      phone: '(1-877-669-6877)'
    };

    public static US_STATES = [
      {name: 'Alabama', code: 'AL'},
      {name: 'Alaska', code: 'AK'},
      {name: 'Arizona', code: 'AZ'},
      {name: 'Arkansas', code: 'AR'},
      {name: 'California', code: 'CA'},
      {name: 'Colorado', code: 'CO'},
      {name: 'Connecticut', code: 'CT'},
      {name: 'Delaware', code: 'DE'},
      {name: 'District Of Columbia', code: 'DC'},
      {name: 'Florida', code: 'FL'},
      {name: 'Georgia', code: 'GA'},
      {name: 'Hawaii', code: 'HI'},
      {name: 'Idaho', code: 'ID'},
      {name: 'Illinois', code: 'IL'},
      {name: 'Indiana', code: 'IN'},
      {name: 'Iowa', code: 'IA'},
      {name: 'Kansas', code: 'KS'},
      {name: 'Kentucky', code: 'KY'},
      {name: 'Louisiana', code: 'LA'},
      {name: 'Maine', code: 'ME'},
      {name: 'Maryland', code: 'MD'},
      {name: 'Massachusetts', code: 'MA'},
      {name: 'Michigan', code: 'MI'},
      {name: 'Minnesota', code: 'MN'},
      {name: 'Mississippi', code: 'MS'},
      {name: 'Missouri', code: 'MO'},
      {name: 'Montana', code: 'MT'},
      {name: 'Nebraska', code: 'NE'},
      {name: 'Nevada', code: 'NV'},
      {name: 'New Hampshire', code: 'NH'},
      {name: 'New Jersey', code: 'NJ'},
      {name: 'New Mexico', code: 'NM'},
      {name: 'New York', code: 'NY'},
      {name: 'North Carolina', code: 'NC'},
      {name: 'North Dakota', code: 'ND'},
      {name: 'Ohio', code: 'OH'},
      {name: 'Oklahoma', code: 'OK'},
      {name: 'Oregon', code: 'OR'},
      {name: 'Pennsylvania', code: 'PA'},
      {name: 'Rhode Island', code: 'RI'},
      {name: 'South Carolina', code: 'SC'},
      {name: 'South Dakota', code: 'SD'},
      {name: 'Tennessee', code: 'TN'},
      {name: 'Texas', code: 'TX'},
      {name: 'Utah', code: 'UT'},
      {name: 'Vermont', code: 'VT'},
      {name: 'Virginia', code: 'VA'},
      {name: 'Washington', code: 'WA'},
      {name: 'West Virginia', code: 'WV'},
      {name: 'Wisconsin', code: 'WI'},
      {name: 'Wyoming', code: 'WY'}
    ];

  public static DEFAULT_STATE_CODE = 'XX';

  public static APP_IDS = {
    COMM_SVC_PLUS : 'comm_svc_plus',
    NSS_COMM_SALES: 'nss_comm_sales',
    NF_LIFE_INSURANCE: 'nf_life_insurance',
    NSS_PERSONAL_SALES: 'nss_personal_sales',
    ERROR: 'error',
    DEFAULT: 'default'
  };

  public static TOKEN = {
    COMMA: ',',
    SPACE: ' ',
  };

  public static DISCAILMERS:{[k: string]: string} = {
    default: 'By clicking the Schedule button, I authorize the Nationwide Family of Companies and its agents to contact me for sales and marketing purposes using live operators, automated telephone dialing systems, or prerecorded messages. I am not required to consent as a condition id purchasing goods or services. I may revoke this consent at any time.',

    nss_personal_sales: 'By clicking the Schedule button, I authorize the Nationwide Family of Companies and its agents to contact me for sales and marketing purposes using live operators, automated systems, or prerecorded messages. I am not required to consent as a condition id purchasing goods or services. I may revoke this consent at any time.'
  };

  public static CLIENT_REF_ID_LENGTH = 6;

  public static EMAIL = {
    TEMPLATE: 'NSS_C2CB',
    REQUESTOR: 'C2CB',
    FROM: {
      ADDRESS: 'noreply@email-nationwide.com',
      NAME: 'Nationwide Insurance',
    },
    MESSAGE_BODY: 'We are looking forward to helping you protect your family\'s future.',
    TOLL_FREE_NUMBER: '1-800-6406233'
  };

  public static DEFAULT_CAL_PERIOD = '60'; // 60 days

  public static WEEK_DAYS = [
    {dayOfWeek: 1, day: 'Sunday'},
    {dayOfWeek: 2, day: 'Monday'},
    {dayOfWeek: 3, day: 'Tuesday'},
    {dayOfWeek: 4, day: 'Wednesday'},
    {dayOfWeek: 5, day: 'Thursday'},
    {dayOfWeek: 6, day: 'Friday'},
    {dayOfWeek: 7, day: 'Saturday'}
  ]

  public static RESCHEDULED = 'rescheduled';
  public static SCHEDULED_HEADING = 'Schedule a call';
  public static RESCHEDULED_HEADING = 'Reschedule this call';

  public static EXECUTION_PHASES = {
    SCHEDULE: {
      init: { text: 'Schedule a call', description: 'Tell us how to reach you. All fields are required unless otherwise noted.' },
      complete: { text: 'Call scheduled', description: '' }
    },
    RESCHEDULE: {
      init: { text: 'Reschedule this call', description: 'Find a time that works for you.' },
      complete: { text: 'Call rescheduled', description: '' }
    },
    CANCEL :{
      init: { text: 'Cancel or Reschedule a call', description: '' },
      complete: { text: 'Call cancelled', description: '' }
    },
    UNAUTHORIZED: {
      init: { text: 'Sorry, something is amiss', description: '' },
      complete: { text: 'Sorry, something is amiss', description: '' }
    }
  }

}
