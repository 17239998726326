export abstract class ScheduleBaseComponent {

  currentYear: string;
  pageTitle: string;
  titleDescription: string;

  constructor(public executionPhase: any) {
    this.currentYear = (new Date().getFullYear()).toString();
    this.pageTitle = executionPhase.init.text;
    this.titleDescription = executionPhase.init.description;
  }

  public setPageHeaderAndTitle(executionPhase: any) {
    this.pageTitle = executionPhase.text;
    this.titleDescription = executionPhase.description;
  }

  public log(obj: any): void {
    console.log(JSON.stringify(obj));
  }
}