import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { catchError, map } from 'rxjs/operators';
import { BaseServiceProvider } from '../base-service-provider';
import { AppConstants } from '../../app-constants';
import { v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class EmailProviderService extends BaseServiceProvider {

  private mailServiceProviderUrl: string;
  private mailServiceProviderClientId: string;
  private emailApiResponse = {
    status: '',
    transactionId: '',
    userMessage: '',
    developerMessage: ''
  };
  private _ackEmailSenddDisabled: boolean = false;

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
    super(messageProvider);
    const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
    const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
    this.mailServiceProviderUrl = envConfig.mailServiceProviderUrl;
    this.mailServiceProviderClientId = secureKeys.emailProviderClientId;
    this._ackEmailSenddDisabled = (envConfig.ackEmailSendDisabled) && (envConfig.ackEmailSendDisabled === true);
  }

  sendNotification(request: any) {
    const headers = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('client_id', this.mailServiceProviderClientId)
                    .set('X-NW-Message-ID', 'SCH-CALL-EM' + uuidv4());

    const options = { headers };

    return this.http.post<any>(this.mailServiceProviderUrl, request, options).pipe(
      map(response => {
        if (response.hasOwnProperty('status')) {
          this.emailApiResponse.status = response.status;
        } else if (response.hasOwnProperty('userMessage')) {
          this.emailApiResponse.userMessage = response.userMessage;
          this.emailApiResponse.developerMessage = response.developerMessage;
        }
        this.emailApiResponse.transactionId = response.transactionId;
        return this.emailApiResponse;
      }),
      catchError(this.handleError<any>('sendNotification', [ this.emailApiResponse ]))
    );
  }

  ackEmailSendDisabled(): boolean {
    return this._ackEmailSenddDisabled;
  }
}
