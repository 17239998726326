<div class="container">

  <!-- header -->
  <header role="banner">
    <div class="page-header">
      <bolt-logo></bolt-logo>
    </div>
  </header>

  <!-- main body -->
  <main role="main">
    <br/>
    <h1 class="bolt-heading-lg bolt-heading-teal-medium title">{{pageTitle}}</h1>
    <div class="">

        <div *ngIf="!callScheduledSuccessfully" id="title-description">{{titleDescription}}</div>

        <bolt-notification *ngFor="let m of messages" type="{{m.type}}" id="notifications">{{m.text}}</bolt-notification>
        <br/>
        <div *ngIf="!callScheduledSuccessfully">
          <form [formGroup]="callScheduleForm" (ngSubmit)="schedule()" #csform="ngForm" setFocusOnError>
            <div class="main-body">

              <div class="formRow" *ngIf="businessNameInputAllowed">
                <div class="mat-form-field">
                    <label for="businessName">Business name</label>
                    <input matInput formControlName="businessName" id="businessName" required>
                </div>
              </div>

              <div class="formRow">
                <div class="mat-form-field">
                    <label for="firstName">First name</label>
                    <input matInput formControlName="firstName" id="firstName" maxlength="100" aria-describedby="call-firstName-error" required>
                    <div aria-live="assertive" role="alert">
                      <div *ngIf="csform.submitted && callScheduleForm.get('firstName')?.invalid" class="error-msg">
                        <div *ngIf="callScheduleForm.get('firstName')?.invalid" id="call-firstName-error">
                          <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                          First name is invalid
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="formRow">
                <div class="mat-form-field">
                    <label for="lastName">Last name</label>
                    <input matInput formControlName="lastName" id="lastName" maxlength="100" aria-describedby="call-lastName-error" required>
                    <div aria-live="assertive" role="alert">
                      <div *ngIf="csform.submitted && callScheduleForm.get('lastName')?.invalid" class="error-msg">
                        <div *ngIf="callScheduleForm.get('lastName')?.invalid" id="call-lastName-error">
                          <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                          Last name is invalid
                        </div>
                      </div>
                    </div>
                </div>
              </div> <!-- formRow -->

              <div class="formRow">
                <div class="mat-form-field">
                  <label for="phoneNumber">Phone number</label>
                  <input matInput #phonum formControlName="phoneNumber" id="phoneNumber" appPhoneMask maxlength="14" aria-describedby="phone-number-error" required>
                  <div aria-live="assertive" role="alert">
                    <div *ngIf="csform.submitted && callScheduleForm.get('phoneNumber')?.invalid" class="error-msg">
                      <div *ngIf="callScheduleForm.get('phoneNumber')?.invalid" id="phone-number-error">
                        <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                        Phone number is invalid
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="mat-form-field">
                  <label for="ext">Ext. <span>(optional)</span></label>
                  <input matInput formControlName="phoneExt" id="ext" maxlength="11">
                </div> -->
              </div> <!-- formRow -->

              <div class="formRow" *ngIf="stateInputAllowed">
                <div class="mat-form-field">
                  <label for="state">State</label>
                  <select formControlName="state" id="state" aria-describedby="call-state-error" required>
                      <option value="" disabled selected>Select</option>
                      <option *ngFor='let st of usStates' [value]="st.code" attr.aria-label="{{st.name}}">{{st.name}}
                      </option>
                  </select>
                  <div aria-live="assertive" role="alert">
                    <div *ngIf="csform.submitted && callScheduleForm.get('state')?.invalid" class="error-msg">
                      <div *ngIf="callScheduleForm.get('state')?.invalid" id="call-state-error">
                        <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                        State is not selected
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="formRow" *ngIf="emailInputAllowed">
                <div class="mat-form-field">
                  <label for="email">Email <span>(optional)</span></label>
                  <input matInput formControlName="email" id="email" maxlength="50">
                  <div aria-live="assertive" role="alert">
                    <div *ngIf="csform.submitted && callScheduleForm.get('email')?.invalid" class="error-msg">
                        <div *ngIf="callScheduleForm.get('email')?.invalid" id="call-email-error">
                          <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                          Email is invalid
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- formRow -->

              <div class="formRow" *ngIf="policyNumberInputAllowed">
                <div class="mat-form-field">
                  <label for="policyNumber">Policy number <span>(optional)</span></label>
                  <input matInput formControlName="policyNumber" id="policyNumber" maxlength="16" aria-describedby="call-policyNumber-error">
                  <div aria-live="assertive" role="alert">
                    <div *ngIf="csform.submitted && callScheduleForm.get('policyNumber')?.invalid" class="error-msg">
                        <div *ngIf="callScheduleForm.get('policyNumber')?.invalid" id="call-policyNumber-error">
                          <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                          Policy number is invalid
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="operateDuringBusinessHours">
                <fieldset class="when-to-call">
                  <legend>When would you like to call?</legend>
                    <div class="formRow">
                      <div class="mat-radio-group">
                        <div class="mat-radio-button">
                            <input type="radio" class="form-check-input" name="callWhen" id="callnow"
                                [value]="callWhenSelection[0].val" checked formControlName="callWhen">
                            <label class="" for="callnow">{{callWhenSelection[0].text}}</label>
                        </div>
                        <div class="mat-radio-button">
                            <input type="radio" class="form-check-input" name="callWhen" id="calllater"
                                [value]="callWhenSelection[1].val" formControlName="callWhen" [attr.aria-expanded]="collapsibleElement">
                            <label class="" for="calllater">{{callWhenSelection[1].text}}</label>
                        </div>
                      </div>
                    </div> <!-- formRow -->
                </fieldset>
              </div>

              <div *ngIf="!callmeNow" class="{{callScheduleStyleClass}}">
                <div class="formRow">
                    <div class="mat-form-field">
                        <div class="form-group">
                            <div class="input-group date">
                                <label for="callDate">Call date</label>
                                <input name="dp" formControlName="scheduledDate" container="body" maxlength="10"
                                    ngbDatepicker #d="ngbDatepicker" [minDate]="calendarProvider.minDate" [maxDate]="calendarProvider.maxDate"
                                    [markDisabled]="calendarProvider.closedDate" id="callDate" (ngModelChange)="onDateChange()"
                                    (change)="onDateChange()" (keypress)="dateCharsOnly($event)" aria-describedby="call-date-error date-format" >
                                <span class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" aria-label="Call Date Picker"
                                        (click)="d.toggle()" type="button">
                                        <bolt-icon name="calendar"></bolt-icon>
                                    </button>
                                </span>
                                <div class="call-date-info" id="date-format">Use this format: MM/DD/YYYY</div>
                            </div>
                            <div aria-live="assertive" role="alert">
                                <div *ngIf="csform.submitted && callScheduleForm.get('scheduledDate')?.invalid" class="error-msg">
                                    <div *ngIf="callScheduleForm.get('scheduledDate')?.invalid" id="call-date-error">
                                      <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                                      Call date is invalid
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="formRow">
                    <div class="mat-form-field">
                        <label for="scheduledTime">Call time</label>
                        <select formControlName="scheduledTime" id="scheduledTime" aria-describedby="call-time-error" (change)="onCallTimeChange()">
                            <option value="" disabled selected>Select</option>
                            <option *ngFor='let ti of timeIntervals' [value]="ti.time" role="timer" attr.aria-label="{{ti.label}}">{{ti.interval}}
                            </option>
                        </select>
                        <div *ngIf="csform.submitted && callScheduleForm.get('scheduledTime')?.valid" class="call-date-info">{{selectedTimeDisplay}}</div>
                        <div aria-live="assertive" role="alert">
                            <div *ngIf="csform.submitted && callScheduleForm.get('scheduledTime')?.invalid" class="error-msg">
                                <div *ngIf="callScheduleForm.get('scheduledTime')?.invalid" id="call-time-error">
                                  <bolt-icon name="notification-error" title="error" color="red"></bolt-icon>
                                  Call time is invalid
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- formRow -->
              </div> <!-- not call me now -->

              <br/>
              <div class="formRow">
                <div class="mat-form-field">
                  <div class="bg-container">
                    <div class="bg-header">Call me now hours</div>
                    <div class="bg-content">
                      <dl *ngFor="let ch of callMeHours">
                        <dt>{{ch.days}}</dt>
                        <dd>{{ch.hours}}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>

              <br/>
              <div *ngIf="appConfig.tcpaAllowed">
                {{disclaimer}}
              </div>

              <br />
              <div class="modal-footer" *ngIf="!scheduleServiceCalled || !callScheduledSuccessfully" >
                  <div class="modal-actions">
                      <div class="action-schedule">
                          <button class="bolt-button-wc bolt-button-wc--primary bolt-button" id="schedule-call">Schedule call</button>
                      </div>
                  </div>
              </div>
            </div> <!-- main-body -->
          </form>
        </div>


    </div>
  </main>

  <!-- footer -->
  <div class="bolt-footer bolt-footer-container">
    <div class="bolt-row">
        <div class="bolt-col bolt-footer-logo-basic">
            <bolt-logo color="white" type="nw-vertical" size="md" type="nw-mark"></bolt-logo>
        </div>
    </div>
    <p class="bolt-text-sm bolt-footer-disclaimer">
         &copy;{{currentYear}}. Nationwide Mutual Insurance Company.
    </p>
    <span id="teconsent"></span>
  <div>
</div>
<div id="consent_blackbar" style="position:absolute;left:0"></div>

