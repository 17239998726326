<div class="container">

  <!-- header -->
  <header role="banner">
    <div class="page-header">
      <bolt-logo></bolt-logo>
    </div>
  </header>

  <!-- main body -->
  <main role="main">

    <br/>
    <h1 class="bolt-heading-lg bolt-heading-teal-medium title">{{pageTitle}}</h1>
    <div *ngIf="callCancelSuccessful">
      <bolt-notification *ngFor="let m of messages" type="{{m.type}}" id="notifications">{{m.text}}</bolt-notification>
      <br/>
      <div class="button-footer">
        <div class="button-actions">
          <div class="action-reschedule">
            <button class="bolt-button-wc bolt-button-wc--primary bolt-button" (click)="reschedule()">Schedule Call</button>
        </div>
        </div>
      </div>
    </div>

    <div *ngIf="!callCancelSuccessful && !callCancelAbandoned">
      <div class="scheduledInfo">
        <dl>
          <dt>Your call is scheduled for:</dt>
          <dd>{{callScheduledDate | date: 'EEEE, MMMM d, y'}}<br/>{{callScheduledTime}}</dd>
        </dl>
        <br/>
        <dl>
          <dt>Call back number:</dt>
          <dd>({{ callbackNumber | slice:0:3 }}) {{ callbackNumber | slice:3:6 }}-{{ callbackNumber | slice:6 }}</dd>
        </dl>
      </div>
      <br />

      <div *ngIf="callCancelRequested">Are you sure you want to cancel this call?</div><br/>
      <div class="button-footer">
        <div class="button-actions">
          <div class="action-cancel" *ngIf="!callCancelRequested">
                <button class="bolt-button-wc bolt-button-wc--primary bolt-button" (click)="cancelCall()" id="cancel-call">Cancel call</button>
                <button class="bolt-button-wc bolt-button-wc--primary bolt-button" (click)="reschedule()" id="reschedule">Reschedule call</button>
          </div>
          <div class="action-cancel" *ngIf="callCancelRequested">
            <button class="bolt-button-wc bolt-button-wc--primary bolt-button" (click)="cancelYes()" id="cancel-yes">Yes</button>
            <button class="bolt-button-wc bolt-button-wc--primary bolt-button" (click)="cancelNo()" id="cancel-no">No</button>
          </div>
        </div>
      </div>
    </div> <!-- call cancell not successful -->

    <div *ngIf="callCancelAbandoned">
      <bolt-notification *ngFor="let m of messages" type="{{m.type}}" id="notifications">{{m.text}}</bolt-notification>
      <br/>
      <div class="scheduledInfo">
        <dl>
          <dt>Your call is scheduled for:</dt>
          <dd>{{callScheduledDate | date: 'EEEE, MMMM d, y'}}<br/>{{callScheduledTime}}</dd>
        </dl>
        <br/>
        <dl>
          <dt>Call back number:</dt>
          <dd>({{ callbackNumber | slice:0:3 }}) {{ callbackNumber | slice:3:6 }}-{{ callbackNumber | slice:6 }}</dd>
        </dl>
      </div>
    </div>
  </main>

  <!-- footer -->
  <div class="bolt-footer bolt-footer-container">
    <div class="bolt-row">
        <div class="bolt-col bolt-footer-logo-basic">
            <bolt-logo color="white" type="nw-vertical" size="md" type="nw-mark"></bolt-logo>
        </div>
    </div>
    <p class="bolt-text-sm bolt-footer-disclaimer">
         &copy;{{currentYear}}. Nationwide Mutual Insurance Company.
    </p>
    <span id="teconsent"></span>
  <div>
</div>
<div id="consent_blackbar" style="position:absolute;left:0"></div>
