import { Injectable } from '@angular/core';
import { CalendarHelper } from '../../shared/utils/calendar-helper';
import { PhoneNumberFormat } from '../../shared/formatters/phone-number-format';
import { AppConstants } from '../../app-constants';
import { SimpleMessage } from './simple-message';
import { ContextCacheError } from '../../schedule-a-call/model/context-cache-error';
import { ScheduleCallbackResponse } from '../schedule-callback-provider/schedule-callback-response';
import { ScheduleACallApiErrorResponse } from '../schedule-callback-provider/schedule-a-call-api-error-response';

@Injectable({
  providedIn: 'root'
})
export class MessageProviderService {

  messages: SimpleMessage[] = [];

  constructor(private phoneNumberFormat: PhoneNumberFormat,
              public calendarHelper: CalendarHelper) {}

  add(message: string, messageType: string): void {
    this.clear();
    this.messages.push({text: this.mapToDisplayMessage(message), type: messageType});
  }

  clear(): void {
    this.messages = [];
  }

  get(): SimpleMessage[] {
    return this.messages;
  }

  public mapToDisplayMessage(message: string): any {
    let returnMessage = AppConstants.MESSAGES.DEFAULT;
    let messageType = 'error';
    if (message.match(/^.*Account number [a-zA-Z0-9]+ does not exist$/)) {
      returnMessage = AppConstants.MESSAGES.ACCOUNT_NOT_FOUND;
    } else if (message.match(/^.*Http failure response for*$/)) {
      returnMessage = AppConstants.MESSAGES.SERVICE_IS_DOWN;
    } else if (message.includes('call scheduling successful')) {
      returnMessage = AppConstants.MESSAGES.CALL_SCHEDULE_SUCCESS;
      messageType = 'success';
    } else if (message.includes('Agent Center launch was not successful')) {
      returnMessage = AppConstants.MESSAGES.AGENT_CENTER_LAUNCH_FAILURE;
    } else if (message.includes('not allowed') || message.includes('idToken: invalid token')) {
      returnMessage = AppConstants.MESSAGES.AGENT_UNAUTHORIZED_ACCESS;
    } else if (message === AppConstants.DISALLOWED_ROUTE) {
      returnMessage = AppConstants.MESSAGES.ERROR_ROUTE
    }
    return {text: returnMessage, type: messageType};
  }

  public getCallScheduleSuccessMessage(response: ScheduleCallbackResponse): any {
    const firstName = response.firstName;
    const lastName = response.lastName;
    const phoneNumber = this.phoneNumberFormat.getStandardFormat(response.phoneNumber);
    const dateTimeStr = response.scheduledTime;
    const dt = this.calendarHelper.dateTimeStringToDateNTime(dateTimeStr);
    const returnMessage = `A Nationwide professional will call ${firstName} ${lastName} at ${phoneNumber} on ${dt.date} within 15 minutes of the time you scheduled.`;
    return {text: returnMessage, type: 'success'};
  }

  public getCallScheduleErrorMessage(response: ScheduleACallApiErrorResponse): any {
    let returnMessage = response.developerMessage || '';
    if (response.code === 401) {
      returnMessage = AppConstants.MESSAGES.ERROR_ROUTE;
    }
    return {text: returnMessage, type: 'error'};
  }

  public otpMultipleUseError(contextCacheError: ContextCacheError): any {
    let returnMessage = contextCacheError.developerMessage || '';
    if (returnMessage && returnMessage.indexOf('Request OTP could not be verified') >= 0) {
      returnMessage = AppConstants.MESSAGES.OTP_MULTIPLE_USE_ERROR;
    }
    return {text: returnMessage, type: 'error'};
  }
}
