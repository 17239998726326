import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CancelRescheduleCallComponent } from './schedule-a-call/cancel-reschedule-call/cancel-reschedule-call.component';
import { SchduleACallResolver } from './schedule-a-call/schedule-a-call.resolve';
import { ScheduleCommonComponent } from './schedule-a-call/schedule-common/schedule-common/schedule-common.component';
import { ScheduleErrorComponent } from './schedule-a-call/schedule-error/schedule-error.component';
import { AccessGuardProviderService } from './services/access-guard-provider/access-guard-provider.service';
import { HealthComponent  } from './shared/utils/health';

const routes: Routes = [
  { path: '', redirectTo: '/c2cb/schedule', pathMatch: 'full' },
  {
    path: 'c2cb/schedule',
    resolve: { contextInfo : SchduleACallResolver },
    component: ScheduleCommonComponent
  },
  {
    path: 'c2cb/cancel-reschedule',
    canActivate: [AccessGuardProviderService],
    data: { rerouteUrl: 'c2cb/error' },
    component: CancelRescheduleCallComponent
  },
  {
    path: 'c2cb/error',
    component: ScheduleErrorComponent
  },
  {
    path: 'health',
    component: HealthComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
