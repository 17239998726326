import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../app-constants';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';

@Injectable({
  providedIn: 'root'
})
export class SecureKeysProviderService {
  private secretsUrl = '/assets/secure/sec-keys.json';

  constructor(private cacheProvider: CacheProviderService) { }

  public init(): any {
    return from(
    fetch(this.secretsUrl).then((response) => {
    return response.json();
    })
    ).pipe(
      map((secKeys: any) => {
        this.cacheProvider.add(AppConstants.SECURE_KEYS, secKeys);
        return secKeys;
      })).toPromise();
  }
}
