import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheProviderService } from '../gen-cache-provider/cache-provider.service';
import { MessageProviderService } from '../message-provider/message-provider.service';
import { v4 as uuidv4} from 'uuid';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseServiceProvider } from '../base-service-provider';
import { AppConstants } from '../../app-constants';
import { UserConfig } from './user-config';
import { CommonResponseArray } from '../../schedule-a-call/model/common-response-array';

@Injectable({
  providedIn: 'root'
})
export class UserConfigProviderService extends BaseServiceProvider {
  private emptyResponse = {
    key: '',
    value: ''
  };
  private userConfigProviderUrl: string;
  private userConfigProviderClientId: string;

  constructor(private http: HttpClient,
              private cacheProvider: CacheProviderService,
              public messageProvider: MessageProviderService) {
    super(messageProvider);
    const envConfig = this.cacheProvider.get(AppConstants.DYNAMIC_ENV_CONFIG);
    const secureKeys = this.cacheProvider.get(AppConstants.SECURE_KEYS);
    this.userConfigProviderUrl = envConfig.userConfigProviderUrl;
    this.userConfigProviderClientId = secureKeys.apiClientId;
  }

  getUserConfigAttributes(appId: string): Observable<UserConfig[]> {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('client_id', this.userConfigProviderClientId)
    .set('X-NW-Message-ID', 'SCH-CALL-UC-' + uuidv4());

    return this.http.get<CommonResponseArray>(this.userConfigProviderUrl.replace(/(\$\w+?\$)/g, appId), {headers}).pipe(
      map(payload => {
        return payload.response ? payload.response : this.emptyResponse;
      }),
      catchError(this.handleError<any>('getUserConfigAttributes', [
        this.emptyResponse
      ]))
    );
  }

}
