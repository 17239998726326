import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../app-constants';
import { MessageProviderService } from '../../services/message-provider/message-provider.service';

@Component({
  selector: 'app-schedule-error',
  templateUrl: './schedule-error.component.html',
  styleUrls: ['./schedule-error.component.scss']
})
export class ScheduleErrorComponent implements OnInit {

  currentYear: string;
  messages: any = [];

  constructor(private route: ActivatedRoute,
              public injector: Injector) {
    this.currentYear = (new Date().getFullYear()).toString();this.currentYear = (new Date().getFullYear()).toString();
  }

  ngOnInit(): void {
    const errorReason = this.route.snapshot.paramMap.get('reason') || AppConstants.DISALLOWED_ROUTE;
    this.messages.push(this.injector.get<MessageProviderService>(MessageProviderService).mapToDisplayMessage(errorReason));
  }

}
